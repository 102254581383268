<template>
  <div class="">
    <header class="d-flex justify-content-between align-items-center mb-3">
      <h4 class="font-weight-bold">
        {{ $t("payment.myAddress") }}
      </h4>

      <app-button
        class="w-25"
        @click.native="
          selectedAddress = {};
          $bvModal.show('add-address');
        "
      >
        {{ $t("payment.addNewAddress") }}
      </app-button>
    </header>
    <b-card v-for="locate in locations" :key="locate.value">
      <div
        class="d-flex align-items-center justify-content-between options-border"
      >
        <div class="address-detail">
          <p class="mb-0 font-weight-bold">{{ locate.title }}</p>
          <p class="mb-0">{{ locate.address }}</p>
        </div>
        <div class="address-actions">
          <button
            class="edit-old-address"
            @click="
              selectedAddress = locate;
              $bvModal.show('add-address');
            "
            tabindex="0"
          >
            <i class="las la-edit"></i>
          </button>
          <button
            class="delete-old-address"
            @click="deleteAddress(locate.id)"
            tabindex="0"
          >
            <i class="las la-trash-alt"></i>
          </button>
        </div>
      </div>
    </b-card>

    <address-modal
      @addAddress="getAddress"
      :address="selectedAddress"
    ></address-modal>
  </div>
</template>

<script>
import { core } from "@/config/pluginInit";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import AddressModal from "@/components/modal/addressModal.vue";
import AppButton from "../../../components/buttons/appButton.vue";
export default {
  name: "user-addresses",
  components: { AddressModal, AppButton },
  data() {
    return {
      locations: [],
      selectedAddress: {},
    };
  },

  methods: {
    getAddress() {
      marketPlace.getAddress().then((res) => {
        this.locations = res.data.data;
      });
    },
    deleteAddress(id) {
      marketPlace.deleteAddress(id).then((res) => {
        core.showSnackbar("success", res.data.message);
        this.locations = this.locations.filter((location) => {
          return location.id !== id;
        });
      });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    this.getAddress();
  },
  watch: {
    selectedAddress(val) {
      if (!val) {
        this.location = {
          title: "",
          name: localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo")).user.name
            : "",
          phone: localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo")).user.phone
            : "",
          address: "",
        };
        this.city_id = "";
        this.area_id = "";
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!localStorage.getItem("userToken")) {
      next({ name: "auth" });
    } else next();
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 1.5rem;
}

.options-border {
  padding: 0.5rem 1rem;
}

.modal-content {
  background-color: var(--iq-bg-light-color) !important;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}

.address-detail {
  color: #777777 !important;
  font-size: 1.2rem;
  font-weight: 500;

  p {
    margin: 0;
  }
}
.address-actions {
  button {
    background: transparent;
    border: none;

    i {
      font-size: 1.5rem;
    }
  }
  .edit-old-address {
    color: #00443d;
    i {
      font-size: 1.5rem;
    }
  }
  .delete-old-address {
    color: #d62020;
  }
}
</style>


